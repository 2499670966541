import { render, staticRenderFns } from "./Notification.vue?vue&type=template&id=088f908a&scoped=true&"
import script from "./Notification.vue?vue&type=script&lang=js&"
export * from "./Notification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088f908a",
  null
  
)

export default component.exports