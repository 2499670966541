<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/notification.svg')" class="panel-icon" alt=""> Cài đặt thông báo</h3>

				        <Notification :data="formNotify"></Notification>

				        <form action="#" @submit.prevent="add">
				        	<div class="form-group">
				        		<label>Hiển thị :</label>
				        		<select class="form-control" v-model="formNotify.type">
				        			<option :value="k" v-for="(notiType, k) in site.notificationType">Tại phần {{ notiType.toLowerCase() }}</option>
				        		</select>
				        	</div>
				        	<div class="form-group">
				        		<label>Nội dung :</label>
				        		<textarea class="form-control" :rows="rows" v-model="formNotify.content" placeholder="Nhập nội dung thông báo, không giới hạn thông báo tại 1 chỗ trừ trang chủ..." required="" @input="upRows"></textarea>
				        	</div>
				        	<div class="form-group">
				        		<label>Màu sắc :</label>
				        		<select class="form-control" v-model="formNotify.class">
				        			<option :value="k" v-for="(opt, k) in classOpt">{{ opt }}</option>
				        		</select>
				        	</div>
				        	<button class="btn b-info"><i class="fa fa-bell"></i> Thêm thông báo</button>
				        </form>
				    </div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/lists.svg')" class="panel-icon" alt=""> Danh sách thông báo</h3>

				        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <select class="form-control" v-model="search.limit" @change="getNotification">
                                        <option :value="rows" v-for="rows in $store.state.limitRowsTable">{{ rows | tableRows }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="search.text" placeholder="Tìm kiếm...">
                                </div>
                            </div>
                        </div>

				        <div class="table-responsive">
				        	<table class="table table-bordered table-hover">
				        		<thead>
				        			<tr>
				        				<th>#</th>
				        				<th>Thao tác</th>
				        				<th>Thông báo</th>
				        				<th>Trạng thái</th>
				        				<th>Hiển thị</th>
				        				<th>Thời gian</th>
				        			</tr>
				        		</thead>
				        		<tbody>
				        			<tr v-for="(noti, k) in filter">
				        				<td>{{ k + 1 }}</td>
				        				<td>
				        					<div class="btn-action">
                                                <button class="btn b-info btn-sm" @click="data(noti)">
                                                <i class="fa fa-edit"></i>
                                                </button>
                                                <button class="btn b-danger btn-sm" @click="remove(noti)">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </div>
				        				</td>
				        				<td>
				        					<Notification :data="noti"></Notification>
				        				</td>
				        				<td>
				        					{{ noti.type }}
				        				</td>
				        				<td>
				        					{{ noti.status == 1 ? 'Bật' : 'Tắt' }}
				        				</td>
				        				<td>
				        					{{ noti.action_time | timeText }}
				        				</td>
				        			</tr>
				        		</tbody>
				        	</table>
				        </div>
				    </div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                    	<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        <h4 class="modal-title" id="exampleModalCenterTitle">Cập nhật thông báo</h4>
                    </div>
                    <div class="modal-body">
                        <form action="#" @submit.prevent="update">
				        	<div class="form-group">
				        		<label>Hiển thị :</label>
				        		<select class="form-control" v-model="formUpdateNotify.type">
				        			<option :value="k" v-for="(notiType, k) in site.notificationType">Tại phần {{ notiType.toLowerCase() }}</option>
				        		</select>
				        	</div>
				        	<div class="form-group">
				        		<label>Nội dung :</label>
				        		<textarea class="form-control" :rows="rows" v-model="formUpdateNotify.content" placeholder="Nhập nội dung thông báo..." required="" @input="upRows"></textarea>
				        	</div>
				        	<div class="form-group">
				        		<label>Màu sắc :</label>
				        		<select class="form-control" v-model="formUpdateNotify.class">
				        			<option :value="k" v-for="(opt, k) in classOpt">{{ opt }}</option>
				        		</select>
				        	</div>
				        	<div class="form-group">
				        		<label>Trạng thái :</label>
				        		<select class="form-control" v-model="formUpdateNotify.status">
				        			<option value="1">Bật</option>
				        			<option value="0">Tắt</option>
				        		</select>
				        	</div>
				        	<button class="btn b-info btn-block"><i class="fa fa-bell"></i> Cập nhật thông báo</button>
				        </form>
                    </div><br>
                </div>
            </div>
        </div>
	</div>
</template>

<style scoped=""></style>

<script>

	export default {
		props: ['notification', 'site'],
		data () {
			return {
				formNotify: {
					class: Object.keys(this.site.notificationClass)[0],
					type: '',
					status: 1
				},
				classOpt: this.site.notificationClass,
				search: {
					limit: 50,
					text: ''
				},
				formUpdateNotify: {},
				customLists: [],
				rows: 4
			}
		},
		created () {
			this.formNotify.type = Object.keys(this.site.notificationType)[0];
			this.getNotification();
		},
		computed: {
			filter () {
				if (this.search.text) {
					return this.customLists.filter(x => {
						return (
							x.content.toLowerCase().includes(this.search.text.toLowerCase()) ||
							x.type.toLowerCase().includes(this.search.text.toLowerCase())
						);
					});
				}
				return this.customLists.filter(x => {
					if (x.content.length >= 100) {
						x.content = x.content.substr(0, 100) + '...';
					}
					return x;
				});
			}
		},
		methods: {
			updateAll (data) {
				if (data.status == 1) {
					this.getNotification();
					this.$store.dispatch('siteData');
				}
				this.$swal('Thông báo', data.message, (data.status == 1 ? 'success' : 'error'));
			},
			add () {
				this.$http.post('admin/notification?action=add', this.formNotify).then(res => {
					this.updateAll(res.body);
                });
			},
			data (noti) {
				this.$http.post('admin/notification?action=data', {id: noti.id}).then(res => {
					if (res.body.status == 1) {
						jQuery(($) => {
							$('#exampleModalCenter').modal('show');
						});
						this.formUpdateNotify = res.body.data;
					}
				});
			},
			update () {
				this.$http.post('admin/notification?action=update', this.formUpdateNotify).then(res => {
					this.updateAll(res.body);
				});
			},
			remove (noti) {
				this.$swal({
					title: 'Chắc chắn?',
					html: 'Chắc chắn xóa thông báo này?',
					imageUrl: require('@/assets/images/icons/notification.svg'),
					imageHeight: '100px',
					showCancelButton: 1,
					confirmButtonText: 'Đồng ý'
				}).then((isConfirm) => {
					if (isConfirm.value) {
						this.$http.post('admin/notification?action=delete', {id: noti.id}).then(res => {
							this.updateAll(res.body);
						});
					}
				})
			},
			upRows () {
				this.rows = this.formNotify.content.split("\n").filter(i => i).length;
				if (this.rows < 1) {
					this.rows = 1;
				}
				if ((this.rows + 1) >= 6) {
					this.rows = (this.rows + 1);
				} else {
					this.rows = 4;
				}
			},
			getNotification () {
				this.$http.post('admin/notification?action=lists', this.search).then(res => {
					this.customLists = res.body;
				});
			}
		}
	}
</script>